<template>
  <v-row>
    <div>
      <vue-element-loading
        :active="appLoading"
        spinner="bar-fade-scale"
        color="#1976d2"
        size="128"
        is-full-screen
      />
    </div>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center fill-height>
      <v-flex xs12 sm12 md5 lg5 xl4>
        <v-card outlined tile height="100%">
          <v-img
            v-if="!curImage"
            :src="baseURL + photos[0]"
            max-height="60vh"
            contain
          ></v-img>
          <v-img
            v-if="curImage"
            :src="baseURL + curImage"
            max-height="60vh"
            contain
          ></v-img>
        </v-card>
      </v-flex>
      <v-flex xs12 sm12 md7 lg7 xl8>
        <v-layout wrap justify-center>
          <v-flex xs11>
            <v-card tile flat>
              <v-layout wrap justify-center>
                <v-flex xs3 v-for="(item, i) in photos" :key="i">
                  <v-card @click="showImage(item)" flat tile>
                    <v-img :src="baseURL + item" height="100px" contain></v-img>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs11>
            <v-divider></v-divider>
          </v-flex>
          <v-layout wrap pt-5 justify-end align-content-center>
            <v-flex xs2 xl12 md2 lg2>
              <v-btn outlined color="red" @click="edit(product._id)"
                >Edit</v-btn
              >
            </v-flex>
          </v-layout>
          <v-flex xs11>
            <v-layout wrap justify-start>
              <v-flex xs12 text-left>
                <span
                  style="font-weight: bold; font-size: 22px; color: #000000"
                >
                  {{ product.productname }}
                </span>
              </v-flex>
              <v-flex xs12 text-left>
                <span style="font-size: 15px; color: #979797">
                  {{ product.caption }}
                </span>
              </v-flex>
              <v-flex xs12 text-left pt-4>
                <v-btn
                  v-if="product.offer == 0"
                  small
                  depressed
                  color="#FF1313"
                  tile
                >
                  <span style="font-size: 12px; color: #ffffff"> 20% </span>
                </v-btn>
              </v-flex>
              <v-flex xs12>
                <v-layout wrap justify-center>
                  <v-flex xs6>
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left>
                        <span style="font-size: 27px; color: #000000">
                          Rs. {{ product.price }}
                        </span>
                      </v-flex>
                      <v-flex xs12 text-left>
                        <span
                          class="text-decoration-line-through"
                          style="font-size: 16px; color: #ff0000"
                          v-if="product.savedamount > 0"
                        >
                          Rs. {{ product.orginalprice }}
                        </span>
                        <span
                          v-if="product.instock"
                          class="pl-4"
                          style="
                            font-size: 16px;
                            font-weight: bold;
                            color: #52d25b;
                          "
                        >
                          In Stock
                        </span>

                        <span
                          v-if="!product.instock"
                          class="pl-4"
                          style="
                            font-size: 14px;
                            font-weight: bold;
                            color: #ff4343;
                          "
                        >
                          Out of Stock
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6 text-left align-self-center>
                    <v-layout wrap justify-start align-content-center>
                      <v-flex xs2 text-right px-2 align-self-center>
                        <span
                          style="font-size: 14px; color: #8f8f8f"
                          class="caption"
                        >
                          {{ product.ratingcount }}
                        </span>
                      </v-flex>
                      <v-flex xs8 text-left px-2 align-self-center>
                        <v-rating
                          :value="parseFloat(product.rating)"
                          color="#FFD341"
                          background-color="#e0e0e0"
                          empty-icon="$ratingFull"
                          half-increments
                          readonly
                          hover
                          small
                        ></v-rating>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 text-left py-4>
                <span v-for="(item, i) in product.size" :key="i" class="px-1">
                  <v-btn
                    small
                    rounded
                    depressed
                    outlined
                    @click="changeSize(item)"
                    :ripple="false"
                    :color="
                      productSize.size == item.size ? '#FF0000' : '#919191'
                    "
                    class="sizebutton px-2"
                  >
                    {{ item.size }}
                  </v-btn>
                </span>
              </v-flex>
              <!-- <v-flex xs12 text-left v-if="productSize.stock">
                <span
                  style="
                    font-size: 12px;
                    color: #ff0000;
                  "
                >
                  {{ productSize.stock }} Left
                </span>
              </v-flex> -->
              <v-flex xs12 text-left pt-2 v-if="productSize.stock">
                <v-layout wrap justify-start>
                  <v-flex xs12 text-left>
                    <span
                      style="
                        font-size: 16px;
                        color: #000000;
                      "
                    >
                      COLOR
                    </span>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start pt-1>
                  <v-flex
                    xs2
                    sm2
                    md1
                    lg1
                    xl1
                    align-self-center
                    v-for="(item, i) in productSize.stock"
                    :key="i"
                    px-2
                  >
                    <v-card
                      outlined
                      rounded="50px"
                      class="colorselector"
                      width="40px"
                      @click="selectProduct(item)"
                      :color="
                        selectedProduct.color == item.color ? '#a9a9a9' : ''
                      "
                    >
                      <v-layout py-2 wrap align-content-center>
                        <v-flex xs12 text-center>
                          <v-avatar :color="item.color" size="15" />
                        </v-flex>
                        <v-flex xs12 style="padding-top: 2px" text-center>
                          <span
                            style="
                              font-size: 12px;
                              color: #808080;
                            "
                            >{{ item.stock }}</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 text-left pt-2>
                <v-layout wrap justify-start>
                  <v-flex xs6 sm6 md6 lg4 xl2 pr-2>
                    <span
                      style="font-weight: bold; font-size: 20px; color: #000000"
                    >
                      STOCK QUANTITY
                    </span>
                    <span
                      class="pl-4"
                      style="font-size: 16px; font-weight: bold; color: #52d25b"
                    >
                      {{ product.stockquantity }}
                    </span>
                  </v-flex>
                </v-layout>
                <v-divider></v-divider>
                <v-layout wrap justify-start pt-5>
                  <v-flex xs12 text-left>
                    <span
                      style="
                        font-weight: bold;
                        font-size: 16px;
                        text-transform: none;
                      "
                    >
                      Category
                    </span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <v-chip
                      v-for="(item, i) in categoryArray"
                      :key="i"
                      class="ma-2"
                      style="font-size: 14px"
                    >
                      {{ item.name }}
                    </v-chip>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start>
                  <v-flex xs12 text-left>
                    <span
                      style="font-weight: bold; font-size: 16px; color: #000000"
                    >
                      SubCategory
                    </span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <v-chip class="ma-2" style="font-size: 14px">
                      {{ subcat.name }}
                    </v-chip>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start>
                  <v-flex xs12 text-left>
                    <span
                      style="font-weight: bold; font-size: 16px; color: #000000"
                    >
                      Tags
                    </span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <v-chip
                      v-for="(item, i) in product.tags"
                      :key="i"
                      class="pl-4 ma-2"
                      style="font-size: 14px"
                    >
                      {{ item }}
                    </v-chip>
                  </v-flex>
                </v-layout>
                        <v-layout wrap justify-start>
                  <v-flex xs12 text-left pt-1 v-if="product.materialused">
                    <span
                      style="font-weight: bold; font-size: 16px; color: #000000"
                    >
                     Material Used
                    </span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <v-chip
                      v-for="(item, i) in product.materialused"
                      :key="i"
                      class="pl-4 ma-2"
                      style="font-size: 14px"
                    >
                      {{ item }}
                    </v-chip>
                  </v-flex>
                </v-layout>
              </v-flex>
              <!-- <v-flex xs12 text-left pt-4>
                <v-layout wrap justify-start>
                  <v-flex xs6 sm6 md6 lg4 xl2 pr-5>
                    <span
                      style="
                        font-weight: bold;
                        font-size: 16px;
                        text-transform: none;
                      "
                    >
                      Profit Margin
                    </span>
                    <span
                      class="pl-4"
                      style="font-size: 14px; font-weight: bold; color: #52d25b"
                    >
                      {{ product.profitmargin }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex> -->
              <v-flex xs12 pt-4>
                <v-layout wrap justify-start>
                  <v-flex xs6 sm6 md6 lg4 xl2 pl-2>
                    <span
                      style="
                        font-weight: bold;
                        font-size: 16px;
                        text-transform: none;
                      "
                    >
                      Tax
                    </span>
                    <span
                      class="pl-4"
                      style="font-size: 14px; font-weight: bold; color: #52d25b"
                    >
                      {{ product.tax }}
                    </span>
                  </v-flex>
                  <v-flex xs6 sm6 md6 lg4 xl2 pl-2>
                    <span
                      style="
                        font-weight: bold;
                        font-size: 16px;
                        text-transform: none;
                      "
                    >
                      GST
                    </span>
                    <span
                      class="pl-4"
                      style="font-size: 14px; font-weight: bold; color: #52d25b"
                    >
                      {{ product.gst }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 pt-4>
                <v-layout wrap justify-start>
                  <!-- <v-flex xs6 sm6 md6 lg4 xl2 pr-2>
                    <v-checkbox
                      v-model="checkbox"
                      :label="`Is Highlated: ${checkbox.toString()}`"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs6 sm6 md6 lg4 xl2 pl-2> -->
                  <v-select
                    dense
                    multiple
                    required
                    v-model="styl"
                    class="mt-5"
                    label="Styl-Category *"
                    :items="stylCategoryArray"
                    item-text="name"
                    item-value="_id"
                  >
                  </v-select>
                  <!-- </v-flex> -->
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <br />
    <v-row v-if="!ServerError" class="justify-center pt-10 pb-10">
      <v-row class="justify-center">
        <div class="px-2">
          <template>
            <v-dialog v-model="dialog" max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" outlined color="red"
                  >Remove Product</v-btn
                >
              </template>
              <v-card>
                <v-card-title
                  >Are you sure you want to remove this Product?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="removeProduct()"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </div>
        <div class="px-2">
          <v-btn outlined :disabled="styl.length<=0" color="red" @click="approveProduct()"
            >Approve Product</v-btn
          >
        </div>
      </v-row>
    </v-row>
  </v-row>
</template>
<script>
import axios from "axios";
export default {
  props: ["productid"],
  data() {
    return {
      productSize: {},
      selectedProduct: {},
      checkbox: true,
      stylCategoryArray: [],
      dialog: false,
      curImage: null,
      styl: "",
      product: [],
      subcat: [],
      categoryArray: [],
      appLoading: false,
      ServerError: false,
      photos: [],
      sizes: [],
      name: "",
    };
  },
  mounted() {
    this.getData();
    this.getStylCategory();
  },
  methods: {
    showImage(item) {
      this.curImage = item;
    },
    changeSize(item) {
      this.productSize = item;
    },
    selectProduct(item) {
      this.selectedProduct = item;
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/product/info",
        params: {
          id: this.productid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.product = response.data.data;
          this.photos = this.product.photos;
          this.subcat = response.data.data.subcategory;
          this.categoryArray = response.data.data.category;
        })
        .catch((e) => {
          this.ServerError = true;
          console.log(e);
        });
    },
    getStylCategory() {
      this.appLoading = true;
      axios({
        url: "/styl/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.stylCategoryArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    removeProduct() {
      axios({
        method: "POST",
        url: "/product/approve",
        data: {
          id: this.productid,
          approve: false,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            // this.getData();
            this.$router.go(-1);
          }
        })
        .catch((e) => {
          this.ServerError = true;
          console.log(e);
        });
    },
    approveProduct() {
      axios({
        method: "POST",
        url: "/product/approve",
        data: {
          id: this.productid,
          approve: true,
          styl: this.styl,
          ishighlated: this.checkbox,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.dialog = false;
          this.appLoading = false;
          if (response.data.status) {
            this.getData();
            this.$router.go(-1);
          }
        })
        .catch((e) => {
          this.ServerError = true;
          console.log(e);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
    edit(pid) {
      this.$router.push({
        name: "productEdit",
        params: { productid: pid },
      });
    },
  },
};
</script>
<style scoped>
.item_title {
  color: #7b7b7b;
  size: 14px;
}
.item_value {
  size: 16px;
}
</style>